import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

function MobileNavigation() {
  const [showMenu, setShowMenu] = useState(false);
  const [lightNavigation, setLightNavigation] = useState(true);
  const currentRoute = useLocation();
  const navigationClass = classNames("burger-menu", {
    light: lightNavigation,
    open: showMenu,
  });

  useEffect(() => {
    setLightNavigation(currentRoute.pathname === "/");
  }, [currentRoute]);

  return (
    <React.Fragment>
      <CustomBurger
        className={navigationClass}
        onClick={() => setShowMenu(!showMenu)}
      >
        <div></div>
        <div></div>
        <div></div>
      </CustomBurger>
      <CustomMobileNavigation className={showMenu ? "open" : ""}>
        <NavLink className="link" to="" onClick={() => setShowMenu(false)}>
          <span></span>À propos
        </NavLink>
        <NavLink
          className="link"
          to="experiences"
          onClick={() => setShowMenu(false)}
        >
          <span></span>Expériences
        </NavLink>
        <NavLink
          className="link"
          to="realisations"
          onClick={() => setShowMenu(false)}
        >
          <span></span>Réalisations
        </NavLink>
        <NavLink
          className="link"
          to="passions"
          onClick={() => setShowMenu(false)}
        >
          <span></span>Passions
        </NavLink>
      </CustomMobileNavigation>
    </React.Fragment>
  );
}

const CustomMobileNavigation = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  background: var(--lightbrown-color);
  padding: 24px;
  transform: translate(100vw, 0);
  transition: 0.628s;
  z-index: 10;

  &.open {
    transform: translate(0, 0);
  }

  .link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 24px;
    height: 24px;
    color: var(--beige-color);
    text-align: center;
    width: fit-content;

    &.active {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 1px;
        background: var(--beige-color);
      }
    }
  }
`;

const CustomBurger = styled.button`
  position: fixed;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
  background: none;
  width: 24px;
  height: 24px;
  z-index: 12;

  &.light {
    div {
      background: var(--beige-color);
    }
  }

  &.open {
    div {
      &:nth-child(1) {
        transform: rotate(45deg);
        width: 30px;
      }

      &:nth-child(2) {
        top: 21px;
        left: 1px;
        width: 30px;
        transform: rotate(-45deg);
      }

      &:nth-child(3) {
        width: 0;
        opacity: 0;
      }
    }
  }

  div {
    position: absolute;
    height: 2px;
    background: var(--pink-color);
    border-radius: 2px;

    &:nth-child(1) {
      width: 24px;
      top: 0;
      left: 0;
      transform-origin: center left;
      transition: 0.628s;
    }
    &:nth-child(2) {
      width: 18px;
      top: 8px;
      transform-origin: bottom left;
      transition: 0.628s;
    }
    &:nth-child(3) {
      width: 12px;
      top: 16px;
      transition: 0.2s;
    }
  }
`;

export default MobileNavigation;
