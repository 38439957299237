import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

function Navigation() {
  const [lightNavigation, setLightNavigation] = useState(true);
  const currentRoute = useLocation();
  const navigationClass = classNames({
    light: lightNavigation,
  });

  useEffect(() => {
    setLightNavigation(currentRoute.pathname === "/");
  }, [currentRoute]);

  return (
    <CustomNavigation className={navigationClass}>
      <NavLink className="link" to="">
        <span></span>À propos
      </NavLink>
      <NavLink className="link" to="experiences">
        <span></span>Expériences
      </NavLink>
      <NavLink className="link" to="realisations">
        <span></span>Réalisations
      </NavLink>
      <NavLink className="link" to="passions">
        <span></span>Passions
      </NavLink>
    </CustomNavigation>
  );
}

const CustomNavigation = styled.nav`
  position: fixed;
  left: 24px;
  display: flex;
  flex-direction: column;
  gap: 120px;
  color: var(--pink-color);
  z-index: 10;

  &.light {
    color: var(--beige-color);

    &::before {
      background: var(--beige-color);
    }

    .link {
      span {
        background: var(--beige-color);
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 8px;
    bottom: 8px;
    width: 1px;
    background: var(--pink-color);
    transition: 0.628s;
  }

  .link {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 8px;
    height: 24px;
    transition: 0.628s;

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      flex-shrink: 0;
      background: var(--pink-color);
      transition: 0.628s;
    }

    &.active,
    &:hover {
      font-size: 16px;

      span {
        width: 12px;
        height: 12px;
        margin-left: -2px;
      }
    }
  }
`;

export default Navigation;
