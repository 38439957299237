import React from "react";
import { Link, useParams } from "react-router-dom";
import enums from "./../enums/projects.json";
import styled from "styled-components";
import ProjectPicture from "../components/ProjectPicture";
import { ReactComponent as Back } from "./../assets/svg/Back.svg";

function Project() {
  const route = useParams();
  const project = enums.projects.find((project) => project.slug === route.slug);

  return (
    <View className="view" data-id={project.slug}>
      <div className="content">
        <Link to={`/realisations`} className="go-back">
          <Back />
          Retour
        </Link>
        <ProjectPicture
          pictures={project.pictures}
          videos={project.videos}
          projectName={project.name}
        />
        <div className="description">
          <div className="technos">
            {project.technos.map((techno) => (
              <div className="tag" key={techno}>
                {techno}
              </div>
            ))}
          </div>
          <div className="text">
            <section>
              <h2 className="title-2">{project.name}</h2>
              <p dangerouslySetInnerHTML={{ __html: project.description }}></p>
              {project.url ? (
                <a
                  href={project.url}
                  className="website-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Voir le site
                </a>
              ) : null}
            </section>
            <section>
              <h2 className="title-2">Mes missions</h2>
              <ul>
                {project.works.map((work, index) => (
                  <li key={index}>{work}</li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </View>
  );
}

const View = styled.div`
  &.animation {
    position: fixed;
    z-index: 1;
    top: 0;
  }
  .content {
    grid-template-columns: repeat(4, 1fr) 200px;
    grid-template-rows: repeat(6, 1fr);
  }

  .go-back {
    display: none;
  }

  .description {
    grid-column: 1 / span 4;
    grid-row: 4 / span 3;
    padding-top: 24px;

    .technos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      gap: 8px;
      margin-bottom: 48px;
    }
  }

  h2 {
    font-size: 32px;
    color: var(--pink-color);
  }

  .text {
    display: flex;
    justify-content: space-between;

    p,
    li {
      color: var(--darkbrown-color);
      font-size: 14px;
      line-height: 18px;
    }

    .website-button {
      display: block;
      position: relative;
      color: var(--lightbrown-color);
      margin-top: 24px;
      width: fit-content;
      font-weight: var(--font-bold);

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 2px;
        background: var(--lightbrown-color);
        transition: 0.4s;
      }

      &:hover {
        &::after {
          right: 100%;
        }
      }
    }

    section {
      width: 48%;
    }

    ul {
      list-style-type: circle;

      li {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 992px) {
    .content {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }

    .go-back {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--pink-color);
      font-size: 12px;
      width: fit-content;
      height: 24px;

      svg {
        width: 18px;
      }
    }

    .description {
      grid-column: 1 / span 2;
      grid-row: auto;
    }

    .text {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;

      section {
        width: 100%;
      }
    }

    ul {
      list-style-position: inside;
    }
  }
`;

export default Project;
