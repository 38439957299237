import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import enums from "./../enums/hobbies.json";
import FullscreenPicture from "./../components/FullscreenPicture";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ReactComponent as Instagram } from "./../assets/svg/instagram.svg";
import gsap from "gsap";
import Flip from "gsap/Flip";

function Hobbies() {
  const hobbies = enums.hobbies.map((hobby) => ({
    ...hobby,
    nodeRef: createRef(null),
  }));
  const pictures = hobbies.map((hobby) => hobby.url);
  const [fullscreen, setFullscreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const nodeRef = useRef(null);
  const tl = new gsap.timeline();
  const enterTl = new gsap.timeline();

  useEffect(() => {
    const enter = gsap.from(".view.hobbies .description", {
      opacity: 0,
      duration: 0.628,
      delay: 0.4,
    });

    enterTl.add(enter);

    return () => {
      enterTl.kill();
    };
  }, []);

  const onPictureClick = (index) => {
    setCurrentIndex(index);
    setFullscreen(true);
  };

  function onFullscreenEnter() {
    document.querySelector(".view").classList.add("animation");

    gsap.to(".pictures-slider.fullscreen .scroller", {
      scrollTo: {
        x: document.querySelectorAll(".fullscreen-picture")[currentIndex],
      },
      duration: 0,
    });

    const currentPicture = document.querySelectorAll(".grid-element .image")[
      currentIndex
    ];

    const currentFullscreenPicture = document.querySelectorAll(
      ".pictures-slider .picture-container"
    )[currentIndex];

    const flipImage = Flip.fit(currentPicture, currentFullscreenPicture, {
      props: "background-size",
      duration: 0.628,
      absolute: true,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });

    const fadeBackground = gsap.to(".pictures-slider.fullscreen", {
      opacity: 1,
      duration: 0.4,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadePoinsBackground = gsap.to(".pictures-slider-points.fullscreen", {
      opacity: 1,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadeButtonBackground = gsap.to(".fullscreen-button.close", {
      opacity: 1,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      onComplete: () => {
        document.querySelector(".view").classList.remove("animation");
      },
    });

    tl.set(".pictures-slider.fullscreen", { opacity: 0 });
    tl.set(currentFullscreenPicture, { opacity: 0 });
    tl.set(".pictures-slider-points.fullscreen", { opacity: 0 });
    tl.set(".fullscreen-button.close", { opacity: 0 });
    tl.set(".grid-element .current img", { objectFit: "contain" });
    tl.add(flipImage, "flip");
    tl.add(fadeBackground, "flip");
    tl.set(currentFullscreenPicture, { opacity: 1 });
    tl.set(currentPicture, { opacity: 0 });
    tl.add(fadePoinsBackground, "flip-end");
    tl.add(fadeButtonBackground, "flip-end");
    tl.set(".image", { clearProps: true });
  }

  function onFullscreenLeave() {
    document
      .querySelector(".pictures-slider.fullscreen .scroller")
      .classList.add("animation");

    const currentPicture = document.querySelector(
      ".grid-element .image.current"
    );

    const currentFullscreenPicture = document.querySelector(
      ".pictures-slider .picture-container.current"
    );

    const flipImage = Flip.fit(currentFullscreenPicture, currentPicture, {
      props: "overflow,transform,object-fit",
      duration: 0.628,
      absolute: true,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadePoinsBackground = gsap.to(".pictures-slider-points.fullscreen", {
      opacity: 0,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadeButtonBackground = gsap.to(".fullscreen-button.close", {
      opacity: 0,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadeBackground = gsap.to(".pictures-slider.fullscreen", {
      background: "rgba(0,0,0,0)",
      duration: 0.4,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      onComplete: () => {
        document
          .querySelector(".pictures-slider.fullscreen .scroller")
          .classList.remove("animation");
      },
    });

    tl.set(".view *", { clearProps: true });
    tl.set(".pictures-slider.fullscreen .picture-container:not(.current)", {
      opacity: 0,
    });
    tl.set(".pictures-slider.fullscreen .picture-container.current img", {
      width: `${currentPicture.clientWidth}px`,
    });
    tl.set(".picture-container.current img", { objectFit: "cover" });
    tl.set(".image.current img", { objectFit: "cover" });
    tl.set(currentPicture, { opacity: 0 });
    tl.add(flipImage, "flip");
    tl.add(fadePoinsBackground, "flip");
    tl.add(fadeButtonBackground, "flip");
    tl.add(fadeBackground, "flip");
    tl.set(currentPicture, { opacity: 1 });
    tl.set(".pictures-slider.fullscreen", { opacity: 0 });
  }

  return (
    <View className="view hobbies">
      <div className="content">
        <TransitionGroup component={null}>
          {hobbies.map((hobby, index, { nodeRef }) => (
            <CSSTransition
              key={index}
              nodeRef={nodeRef}
              timeout={{ enter: 628 / 2 + Math.random() * 6 * 100, exit: 300 }}
              classNames="card"
              appear={true}
            >
              <div
                key={hobby.name}
                className={hobby.classname}
                onClick={() => onPictureClick(index)}
              >
                <div
                  ref={nodeRef}
                  className={`image ${index === currentIndex ? "current" : ""}`}
                >
                  <img src={hobby.url} alt={hobby.nom} />
                </div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        <div className="description">
          <p>
            La création a une place importante dans ma vie que ce soit dans mon
            métier ou dans mes passions. <br />
            Je suis une touche à tout du fait main: couture, tricot, peinture,
            illustration… je ne connais pas l’ennui&nbsp;!
          </p>
          <a
            href="https://www.instagram.com/marguerite_moods/"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram />
          </a>
        </div>
        <CSSTransition
          in={fullscreen}
          nodeRef={nodeRef}
          timeout={620}
          classNames="fullscreen"
          onEnter={onFullscreenEnter}
          onExit={onFullscreenLeave}
          unmountOnExit={true}
        >
          <FullscreenPicture
            onClose={() => setFullscreen(false)}
            pictures={pictures}
            videos={[]}
            projectName={"hobby"}
            currentIndex={currentIndex}
            nodeRef={nodeRef}
            onIndexChange={setCurrentIndex}
          />
        </CSSTransition>
      </div>
    </View>
  );
}

const View = styled.section`

&.animation{
  .grid-element{
    .current{
      z-index: 100;

      img{
        transform: none;
        transition: none;
      }
    }
  }
}

  .content{
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .grid-element{
    overflow: hidden;
    cursor: pointer;

    .image{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: 0.628s;
      }
    }


    &.landscape{
      grid-column-end: span 2;
    }

    &.portrait{
      grid-row-end: span 2;
    }

    &:hover{
      img{
        transform: scale(1.2);
      }
    }
  }

  .description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    width: 100%;
    padding-left: 24px;
    grid-column: 5 / span 1;
    grid-row: 1 / span 3;

    p{
      color: var(--darkbrown-color);
      font-size: 14px;
      line-height: 20px
    }

    a{
      align-self: center;

      svg{
        width: 24px;
        fill: var(--pink-color);
      }
    }

  }
}

@media (max-width: 992px) {
  .content{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    padding-top: 50vh;
  
  }

  .description{
    grid-column: 1 / span 3;
    grid-row: auto;
    padding: 24px 0 0;
  }

}

@media (max-width: 760px) {
  .content{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding-top:0;
  }

  .description{
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    padding:0 24px 0 0;
  }
}

`;

export default Hobbies;
