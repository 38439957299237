import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Flip from "gsap/Flip";
import FullscreenPicture from "./FullscreenPicture";
import { ReactComponent as Zoom } from "./../assets/svg/zoom.svg";
import { CSSTransition } from "react-transition-group";

function ProjectPicture(props) {
  const pictures = props.pictures;
  const videos = props.videos;
  const projectName = props.projectName;
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const scroller = useRef(null);
  const tl = new gsap.timeline();
  const nodeRef = useRef(null);

  useEffect(() => {
    scroller.current.addEventListener("scroll", setIndexOnScroll);
  }, [scroller]);

  useEffect(() => {
    scrollToPicture(currentPictureIndex);
  }, [currentPictureIndex]);

  const scrollToPicture = (index) => {
    setCurrentPictureIndex(index);

    gsap.to(scroller.current, {
      scrollTo: {
        x: document.querySelectorAll(".image")[index],
        duration: 0.628,
      },
    });
  };

  function setIndexOnScroll(e) {
    const sliderWidth = document.querySelector('.pictures-slider:not(.fullscreen)').clientWidth
    const scrollLeft = e.target.scrollLeft
    setCurrentPictureIndex(Math.round(scrollLeft / sliderWidth))
  }

  function onFullscreenEnter() {
    document.querySelector(".scroller").classList.add("animation");

    gsap.to(".pictures-slider.fullscreen .scroller", {
      scrollTo: {
        x: document.querySelectorAll(".fullscreen-picture")[
          currentPictureIndex
        ],
      },
      duration: 0,
    });

    const currentPicture = document.querySelectorAll(
      ".pictures-slider:not(.fullscreen) .scroller .image"
    )[currentPictureIndex];

    const currentFullscreenPicture = document.querySelectorAll(
      ".pictures-slider .picture-container"
    )[currentPictureIndex];

    const flipImage = Flip.fit(currentPicture, currentFullscreenPicture, {
      props: "overflow,transform",
      duration: 0.628,
      absolute: true,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });

    const fadeBackground = gsap.to(".pictures-slider.fullscreen", {
      opacity: 1,
      duration: 0.4,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadePoinsBackground = gsap.to(".pictures-slider-points.fullscreen", {
      opacity: 1,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadeButtonBackground = gsap.to(".fullscreen-button.close", {
      opacity: 1,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      onComplete: () => {
        document.querySelector(".scroller").classList.remove("animation");
      },
    });

    tl.set(".image:not(.current)", { opacity: 0 });
    tl.set(".pictures-slider.fullscreen", { opacity: 0 });
    tl.set(currentFullscreenPicture, { opacity: 0 });
    tl.set(".pictures-slider-points.fullscreen", { opacity: 0 });
    tl.set(".fullscreen-button.close", { opacity: 0 });
    if (document.querySelector(".image.current img")) {
      tl.set(".image.current img", { objectFit: "contain" });
    }
    tl.add(flipImage, "flip");
    tl.add(fadeBackground, "flip");
    tl.set(currentPicture, { opacity: 0 });
    tl.set(currentFullscreenPicture, { opacity: 1 });
    tl.add(fadePoinsBackground, "flip-end");
    tl.add(fadeButtonBackground, "flip-end");
    tl.set(".image", { opacity: 1 });
    tl.set(".pictures-slider .scroller *", { clearProps: true });
  }

  function onFullscreenLeave() {
    document
      .querySelector(".pictures-slider.fullscreen .scroller")
      .classList.add("animation");

    if (currentPictureIndex !== 0) {
      gsap.to(".pictures-slider .scroller", {
        scrollTo: {
          x: document.querySelectorAll(".image")[currentPictureIndex],
        },
        duration: 0,
      });
    }

    const currentPicture =
      document.querySelectorAll(".scroller .image")[currentPictureIndex];

    const currentFullscreenPicture = document.querySelector(
      ".pictures-slider .picture-container.current"
    );

    const flipImage = Flip.fit(currentFullscreenPicture, currentPicture, {
      props: "overflow,transform",
      duration: 0.628,
      absolute: true,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadePoinsBackground = gsap.to(".pictures-slider-points.fullscreen", {
      opacity: 0,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadeButtonBackground = gsap.to(".fullscreen-button.close", {
      opacity: 0,
      duration: 0.2,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const fadeBackground = gsap.to(".pictures-slider.fullscreen", {
      background: "rgba(0,0,0,0)",
      duration: 0.4,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      onComplete: () => {
        document
          .querySelector(".pictures-slider.fullscreen .scroller")
          .classList.remove("animation");
      },
    });

    tl.set(".picture-container:not(.current)", { opacity: 0 });
    if (document.querySelector(".picture-container.current img")) {
      tl.set(".picture-container.current img", { objectFit: "cover" });
    }
    tl.add(flipImage, "flip");
    tl.add(fadePoinsBackground, "flip");
    tl.add(fadeButtonBackground, "flip");
    tl.add(fadeBackground, "flip");
    tl.set(".pictures-slider.fullscreen", { opacity: 0 });
  }

  return (
    <React.Fragment>
      <Picture className="pictures-slider">
        <div ref={scroller} className="scroller">
          {pictures.concat(videos).map((picture, index) =>
            picture.includes("jpg") ? (
              <div
                key={picture}
                className={`${
                  currentPictureIndex === index ? " image current" : "image"
                }`}
              >
                <img className="picture" src={picture} alt={projectName} />
              </div>
            ) : (
              <div
                className={`${
                  currentPictureIndex === index ? "image current" : "image"
                }`}
                key={picture}
              >
                <video controls>
                  <source src={picture} type="video/mp4"></source>
                </video>
              </div>
            )
          )}
        </div>
        <button
          className="fullscreen-button"
          onClick={() => setFullscreen(true)}
        >
          <Zoom />
        </button>
      </Picture>
      <Points className="pictures-slider-points">
        {pictures.concat(videos).map((picture, index) => (
          <div
            className="point"
            key={index}
            onClick={() => scrollToPicture(index)}
            style={{
              background:
                currentPictureIndex === index ? "var(--pink-color)" : "none",
            }}
          ></div>
        ))}
      </Points>
      <CSSTransition
        in={fullscreen}
        nodeRef={nodeRef}
        timeout={620}
        classNames="fullscreen"
        onEnter={onFullscreenEnter}
        onExit={onFullscreenLeave}
        unmountOnExit={true}
      >
        <FullscreenPicture
          onClose={() => setFullscreen(false)}
          pictures={pictures}
          videos={videos}
          projectName={projectName}
          currentIndex={currentPictureIndex}
          nodeRef={nodeRef}
          onIndexChange={setCurrentPictureIndex}
        />
      </CSSTransition>
    </React.Fragment>
  );
}

const Picture = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1 / span 3;

  .fullscreen-button {
    color: var(--pink-color);
  }

  @media (max-width: 992px) {
    grid-column: 1 / span 2;
    grid-row: auto;
    max-height: 30vh;
  }
`;

const Points = styled.div`
  grid-column: 5 / span 1;
  grid-row: 1 / span 3;
  padding-left: 12px;

  @media (max-width: 992px) {
    grid-column: 1 / span 2;
    grid-row: auto;
  }
`;

export default ProjectPicture;
