import React from "react";
import styled from "styled-components";

function Education(props) {
  const education = props.education;

  return (
    <EducationLi className="education-li">
      <div>
        <h3>{education.name}</h3>
        <p>{education.degree}</p>
        <p className="date">{education.year}</p>
      </div>
      <span></span>
    </EducationLi>
  );
}

const EducationLi = styled.li`
  display: flex;
  gap: 16px;
  margin-right: -5px;

  h3,
  p {
    text-align: right;
    padding: 4px 0;
  }

  h3 {
    font-weight: var(--font-bold);
    color: var(--lightbrown-color);
  }

  p {
    font-size: 12px;

    &.date {
      font-style: italic;
    }
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    flex-shrink: 0;
    background: var(--pink-color);
    margin-top: 6px;
  }
`;

export default Education;
