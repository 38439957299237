import React, { createRef, useState } from "react";
import styled from "styled-components";
import enums from "./../enums/projects.json";
import ProjectCard from "./../components/ProjectCard";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function Projects() {
  const [filter, setFilter] = useState("");
  const projects = enums.projects.map((project) => ({
    ...project,
    nodeRef: createRef(null),
  }));
  const backgroundColors = ["#7b2722", "#a1543a", "#d39a89"];
  const filters = [
    { value: "", name: "Tous" },
    { value: "web", name: "Web" },
    { value: "mobile", name: "Mobile" },
    { value: "proProject", name: "Projets professionnels" },
    { value: "personalProject", name: "Projets personnels" },
  ];

  const filterClass = (value) =>
    classNames({
      active: filter === value,
    });

  function filterProject(project) {
    switch (filter) {
      case "mobile":
        return project.mobile;
      case "web":
        return !project.mobile;
      case "personalProject":
        return project.personalProject;
      case "proProject":
        return !project.personalProject;
      default:
        return project;
    }
  }

  return (
    <View className="view projects">
      <div className="content">
        <div className="filters">
          {filters.map((filter) => (
            <button
              key={filter.value}
              className={filterClass(filter.value)}
              onClick={() => setFilter(filter.value)}
            >
              {filter.name}
            </button>
          ))}
        </div>
        <TransitionGroup component={null}>
          {projects.map((project, index, { nodeRef }) => (
            <CSSTransition
              key={project.name}
              nodeRef={nodeRef}
              timeout={{
                enter: 628 / 2 + (index + 1) * 100,
              }}
              classNames="card"
              appear={true}
            >
              <ProjectCard
                bacgroundColor={backgroundColors[index % 3]}
                project={project}
                index={index}
                ref={nodeRef}
                hidden={!filterProject(project)}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </View>
  );
}

const View = styled.section`
&.animation {
  position: fixed;
  z-index: 0;
  top: 0;

  .overlay,
    .image {
      transition: none;
    }
}

  .filters{
    grid-column: 1 / span 4;
    grid-row: 1 / span 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding-bottom: 24px;

    button{
      background: none;
      cursor: pointer;
      color: var(--pink-color);

      &:hover, &.active{
        color: var(--brown-color)
      }
    }
  }
}

@media (max-width: 992px) {
  .content{
    padding-top: 72px;

  }

  .filters {
    display: none;
  }
}

`;

export default Projects;
