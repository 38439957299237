import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function ProjectCard(props) {
  const [cardClass, setCardClass] = useState("overlay");
  const bacgroundColor = props.bacgroundColor;
  const project = props.project;
  const index = props.index;

  useEffect(() => {
    switch (index % 4) {
      case 0:
        setCardClass("overlay right");
        break;
      case 1:
        setCardClass("overlay top");
        break;
      case 2:
        setCardClass("overlay bottom");
        break;
      default:
        setCardClass("overlay left");
        break;
    }
  }, []);

  return (
    <Card
      style={{
        opacity: props.hidden ? 0 : 1,
      }}
      className="project-card"
      data-id={project.slug}
    >
      <Link to={`/realisations/${project.slug}`} className="link">
        <div
          style={{ backgroundImage: `url(${project.pictures[0]})` }}
          className="image"
        ></div>
        <div style={{ backgroundColor: bacgroundColor }} className={cardClass}>
          <h2>{project.name}</h2>
          <div className="sector">{project.sector}</div>
          <div className="technos">
            {project.mainTechnos.map((techno) => (
              <div className="techno" key={techno}>
                {techno}
              </div>
            ))}
          </div>
        </div>
      </Link>
    </Card>
  );
}

const Card = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: 0.628s;

  &.animating {
    overflow: visible;

    .image {
      z-index: 100;
    }
  }

  .link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    transform: translate(0);
    color: var(--beige-color);
    transition: 0.628s ease-in-out;
    pointer-event: none;

    h2 {
      font-size: 20px;
      font-family: var(--title-font);
      text-align: center;
    }

    .sector {
      border: 1px solid var(--beige-color);
      padding: 8px;
      font-size: 10px;
    }

    .technos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .techno {
        position: relative;
        font-size: 10px;

        &::after {
          content: "-";
          margin-left: 8px;
        }

        &:last-child::after {
          display: none;
        }
      }
    }
  }

  &:hover {
    .overlay {
      &.left {
        transform: translate(-100%, 0);
      }
      &.right {
        transform: translate(100%, 0);
      }
      &.top {
        transform: translate(0, -100%);
      }
      &.bottom {
        transform: translate(0, 100%);
      }
    }
  }

  @media (max-width: 992px) {
    aspect-ratio: 1/1;

    .overlay {
      background: rgba(240, 231, 224, 0.7) !important;
      color: var(--brown-color);
      gap: 12px;

      h2 {
        font-size: 14px;
        line-height: 18px;
      }

      .sector {
        display: none;
      }
      .technos {
        gap: 4px;

        .techno {
          font-weight: var(--font-semibold)
        }
      }
    }

    &:hover {
      .overlay {
        transform: none !important;
      }
    }
  }
`;

export default ProjectCard;
