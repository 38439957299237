import "./styles/App.scss";
import Navigation from "./components/Navigation";
import { Link, useLocation, useOutlet, useParams } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { cloneElement, useEffect, useState } from "react";
import classNames from "classnames";
import router from "./router";
import gsap from "gsap";
import Flip from "gsap/Flip";
import MobileNavigation from "./components/MobileNavigation";

function App() {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const params = useParams();
  const tl = new gsap.timeline();
  let isMobile = window.matchMedia("(max-width: 992px)").matches;

  window.addEventListener("resize", () => {
    isMobile = window.matchMedia("(max-width: 992px)").matches;
  });

  const [lightLogo, setLightLogo] = useState(true);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [previousParams, setPreviousParams] = useState({
    query: null,
  });
  const currentRoute = useLocation();
  const logoClass = classNames({
    light: lightLogo,
  });
  const { nodeRef } = router.routes[0].children.find(
    (route) =>
      `/${route.path}` === location.pathname ||
      (route.path.includes("slug") && params.slug)
  );

  const index = router.routes[0].children.findIndex(
    (route) =>
      `/${route.path}` === location.pathname ||
      (route.path.includes("slug") && params.slug)
  );

  useEffect(() => {
    setLightLogo(currentRoute.pathname === "/");
    setPreviousIndex(index);
    setPreviousParams(params);
  }, [currentRoute]);

  function onProjectAnimationEnter() {
    if (params.slug) {
      enterProjectDetailAnimation();
    } else if (previousParams.slug) {
      const leavingElement = document.querySelector(
        `.view[data-id="${previousParams.slug}"]`
      );
      leavingElement.classList.add("animation");

      gsap.to(leavingElement, {
        opacity: 0,
        duration: 0.4,
        ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      });
    }
  }

  function enterProjectDetailAnimation() {
    const enteringElement = nodeRef.current;
    enteringElement.classList.add("animation");
    const leavingElement = document.querySelector(`.view.projects`);
    leavingElement.classList.add("animation");
    const projectCard = document.querySelector(
      `.project-card[data-id="${params.slug}"]`
    );
    const projectCardImage = document.querySelector(
      `.project-card[data-id="${params.slug}"] .image`
    );
    projectCard.classList.add("animating");

    const flipCard = Flip.fit(
      projectCardImage,
      `.view[data-id="${params.slug}"] .pictures-slider`,
      {
        duration: 0.628,
        absolute: true,
        ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      }
    );

    const filtersFadeAnimation = gsap.to(".view.projects .filters", {
      opacity: 0,
      duration: 0.3,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const cardFadeAnimation = gsap.to(".view.projects .overlay", {
      opacity: 0,
      duration: 0.3,
      ease: "cubic-bezier(0.12, 0, 0.39, 0)",
    });
    const projectFadeimation = gsap.to(
      `.view[data-id="${params.slug}"] .description`,
      {
        opacity: 1,
        duration: 0.4,
        ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      }
    );
    const projectGoBackimation = gsap.to(
      `.view[data-id="${params.slug}"] .go-back`,
      {
        opacity: 1,
        duration: 0.4,
        ease: "cubic-bezier(0.12, 0, 0.39, 0)",
      }
    );
    const picturePointsFadeimation = gsap.to(
      `.view[data-id="${params.slug}"] .pictures-slider-points`,
      {
        opacity: 1,
        duration: 0.4,
        ease: "cubic-bezier(0.12, 0, 0.39, 0)",
        onComplete: () => {
          enteringElement.classList.remove("animation");
          leavingElement.classList.remove("animation");
        },
      }
    );

    tl.set(enteringElement, { opacity: 0 });
    tl.set(`.view[data-id="${params.slug}"] .description`, { opacity: 0 });
    tl.set(`.view[data-id="${params.slug}"] .pictures-slider-points`, {
      opacity: 0,
    });
    tl.set(`.project-card:not([data-id="${params.slug}"]) .image`, {
      opacity: 0,
    });
    if (document.querySelector(`.view[data-id="${params.slug}"] .go-back`)) {
      tl.set(`.view[data-id="${params.slug}"] .go-back`, {
        opacity: 0,
      });
    }
    tl.add(flipCard, "flip");
    tl.add(filtersFadeAnimation, "flip");
    tl.add(cardFadeAnimation, "flip");
    tl.set(enteringElement, { opacity: 1 });
    tl.add(projectFadeimation, "flip-project");
    if (document.querySelector(`.view[data-id="${params.slug}"] .go-back`)) {
      tl.add(projectGoBackimation, "flip-project");
    }
    tl.add(picturePointsFadeimation, "flip-project");
  }

  return (
    <div id="app">
      <Logo className={logoClass}>
        <Link to="/">anaïs lorteau</Link>
      </Logo>
      {isMobile ? <MobileNavigation /> : <Navigation />}
      <TransitionGroup
        childFactory={(child) => {
          return cloneElement(child, {
            classNames:
              params.slug || previousParams.slug
                ? "flip"
                : previousIndex > index
                ? "router-bottom"
                : "router-top",
          });
        }}
      >
        <CSSTransition
          key={location.pathname}
          timeout={params.slug || previousParams.slug ? 1000 : 628}
          classNames="router-bottom"
          nodeRef={nodeRef}
          onEnter={onProjectAnimationEnter}
          unmountOnExit
        >
          {(state) => <div ref={nodeRef}>{currentOutlet}</div>}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

const Logo = styled.div`
  position: fixed;
  top: 24px;
  left: 24px;
  font-family: var(--title-font);
  font-size: 28px;
  color: var(--pink-color);
  z-index: 10;
  transition: 0.3s ease;

  &.light {
    color: var(--beige-color);
  }
`;

export default App;
