import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Landing from "./views/Landing";
import Resume from "./views/Resume";
import Projects from "./views/Projects";
import Project from "./views/Project";
import Hobbies from "./views/Hobbies";
import { createRef } from "react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Landing />,
        nodeRef: createRef(null),
      },
      {
        path: "experiences",
        element: <Resume />,
        nodeRef: createRef(null),
      },
      {
        path: "realisations",
        element: <Projects />,
        nodeRef: createRef(null),
      },
      {
        path: "realisations/:slug",
        element: <Project />,
        nodeRef: createRef(null),
      },
      {
        path: "passions",
        element: <Hobbies />,
        nodeRef: createRef(null),
      },
    ],
  },
]);

export default router;
