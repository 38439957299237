import React, { useEffect } from "react";
import styled from "styled-components";
import LandingCanvas from "../components/LandingCanvas";
import { ReactComponent as Email } from "./../assets/svg/email.svg";
import { ReactComponent as Github } from "./../assets/svg/github.svg";
import { ReactComponent as LinkedIn } from "./../assets/svg/linkedin.svg";
import gsap from "gsap";
import illustration from "./../assets/illustrations/landing-illustration.png";

function Landing() {
  const timeline = gsap.timeline();

  useEffect(() => {
    const meta = document.querySelector("meta[name='theme-color']");
    meta.content = "#d39a89";

    document.documentElement.classList.add("no-scroll");

    const enter = gsap.from(".view.landing .text", {
      opacity: 0,
      duration: 0.628,
      delay: 0.4,
    });
    const enterIllustration = gsap.from(".view.landing .illustration", {
      opacity: 0,
      duration: 0.628,
      delay: 0.4,
    });

    timeline.add(enter, "enter");
    timeline.add(enterIllustration, "enter");

    return () => {
      timeline.kill();
      document.documentElement.classList.remove("no-scroll");
      meta.content = "#f0e7e0";
    };
  }, []);

  return (
    <View className="view landing">
      <LandingCanvas />
      <div className="text">
        <h1 className="title-1">
          Développeuse <br />
          front-end
        </h1>
        <p>
          Motivée par l’envie d’en apprendre toujours plus, j'ai besoin
          d'évoluer dans un univers créatif et stimulant.
          <br />
          <br />
          Mes expériences en marketing me permettent d'avoir une approche
          centrée utilisateurs.
          <br />
          <br />
          En résumé, une vision 360° et un enthousiasme sans faille !
        </p>
        <div className="social">
          <a
            href="https://www.linkedin.com/in/anais-lorteau/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn />
          </a>
          <a
            href="https://github.com/anaislor"
            target="_blank"
            rel="noreferrer"
          >
            <Github className="github" />
          </a>
          <a href="mailto:alorteau@gmail.com" target="_blank" rel="noreferrer">
            <Email />
          </a>
        </div>
      </div>
      <div
        className="illustration"
        style={{ backgroundImage: `url(${illustration})` }}
      ></div>
    </View>
  );
}

const View = styled.section`
  postion: relative;
  color: var(--beige-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &.animation-projects-leave {
    position: fixed;
    z-index: 0;
    top: 0;
  }

  .text {
    position: fixed;
    width: 40vw;
    left: 25vw;
    cursor: default;

    p {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .social {
    display: flex;
    align-items: flex-end;
    gap: 32px;
    margin-top: 32px;

    a {
      transition: 0.628s;

      svg {
        width: 24px;
        fill: var(--beige-color);

        &.github {
          margin-bottom: -2px;
        }
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .illustration {
    position: fixed;
    bottom: 0;
    right: -25vw;
    width: 80vw;
    height: 80vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  @media (max-width: 992px) {
    &::after {
      display: none;
    }

    .text {
      width: 80vw;
      top: 15vh;
      left: unset;

      .title-1 {
        font-size: 42px;
        line-height: 52px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .illustration {
      bottom: -15vh;
      right: -30vw;
      width: 100vw;
      height: 65vh;
    }
  }

  @media (max-width: 760px) {
    .text {
      .title-1 {
        font-size: 36px;
        line-height: 48px;
      }
    }

    .illustration {
      bottom: -15vh;
      right: -40vw;
      width: 100vw;
      height: 50vh;
    }
  }
`;

export default Landing;
