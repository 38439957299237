import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import gsap from "gsap";
import { ReactComponent as Zoom } from "./../assets/svg/zoom-out.svg";

function FullscreenPicture(props) {
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

  const fullscreenScroller = useRef(null);
  const pictures = props.pictures;
  const videos = props.videos;
  const projectName = props.projectName;
  useEffect(() => {
    document.documentElement.classList.add("no-scroll");
    scrollToFullscreenPicture(null, props.currentIndex, 0);

    return () => {
      document.documentElement.classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    fullscreenScroller.current.addEventListener("scroll", setIndexOnScroll);
  }, [fullscreenScroller]);

  const scrollToFullscreenPicture = (e, index, duration = 0.628) => {
    if (e) {
      e.stopPropagation();
    }
    setCurrentPictureIndex(index);
    props.onIndexChange(index);

    gsap.to(fullscreenScroller.current, {
      scrollTo: {
        x: document.querySelectorAll(".fullscreen-picture")[index],
      },
      duration,
    });
  };

  function setIndexOnScroll(e) {
    const sliderWidth = document.querySelector(
      ".pictures-slider.fullscreen"
    ).clientWidth;
    const scrollLeft = e.target.scrollLeft;
    setCurrentPictureIndex(Math.round(scrollLeft / sliderWidth));
    props.onIndexChange(Math.round(scrollLeft / sliderWidth));
  }

  return createPortal(
    <Picture
      className="pictures-slider fullscreen"
      ref={props.nodeRef}
      onClick={props.onClose}
    >
      <div ref={fullscreenScroller} className="scroller">
        {pictures.concat(videos).map((picture, index) =>
          picture.includes(".mp4") ? (
            <div
              key={index}
              className={`${
                currentPictureIndex === index
                  ? "picture-container current"
                  : "picture-container"
              }`}
            >
              <video controls className="fullscreen-picture">
                <source src={picture} type="video/mp4"></source>
              </video>
            </div>
          ) : (
            <div
              className={`${
                currentPictureIndex === index
                  ? "picture-container current"
                  : "picture-container"
              }`}
              key={index}
            >
              <img
                className="fullscreen-picture"
                src={picture}
                alt={projectName}
              />
            </div>
          )
        )}
      </div>
      <button className="fullscreen-button close" onClick={props.onClose}>
        <Zoom />
      </button>
      {pictures.concat(videos).length > 1 && (
        <Points className="pictures-slider-points fullscreen">
          {pictures.concat(videos).map((picture, index) => (
            <div
              className="point"
              key={index}
              onClick={(e) => scrollToFullscreenPicture(e, index)}
              style={{
                background:
                  currentPictureIndex === index ? "var(--pink-color)" : "none",
              }}
            ></div>
          ))}
        </Points>
      )}
    </Picture>,
    document.body
  );
}

const Picture = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--darkbrown-color);
  z-index: 20;

  .scroller {
    display: flex;
    align-items: center;
    overflow: hidden;
    transform: none;

    &.animation {
      .picture-container {
        height: 100%;

        img {
          object-fit: cover;
        }

        video {
          height: 100%;
          width: 100%;
        }
      }
    }

    .picture-container {
      width: 100vw;
      height: 90vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      overflow: hidden;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      video {
        object-fit: contain;
        width: 90%;
        height: 90%;
      }
    }
  }

  @media (max-width: 760px) {
    .scroller {
      @include hiddeScrollbar;
      overflow-x: auto;
      scroll-snap-type: x mandatory;

      &.animation {
        .picture-container {
          video {
            height: auto;
          }
        }
      }

      .picture-container {
        scroll-snap-align: center;

        video {
          height: auto;
        }
      }
    }
  }
`;

const Points = styled.div`
  flex-direction: row;
  position: absolute;
  bottom: 2vh;
  padding: 0;
`;

export default FullscreenPicture;
