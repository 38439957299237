import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Gradient from "./../assets/illustrations/gradient.png";

function LandingCanvas() {
  const isTouchDevice = window.matchMedia("(max-width: 992px)").matches;
  let ctx = null;
  const canvasRef = useRef(null);
  let particlesArray = [];
  let ticker = null;
  const lineWidth = isTouchDevice ? 80 : 160;
  const time = {
    delta: 16,
    elapsed: 0,
    current: Date.now(),
  };

  useEffect(() => {
    initCanvas();

    tick();

    return () => {
      window.cancelAnimationFrame(ticker);
      if (isTouchDevice) {
        window.removeEventListener("touchmove", onMove);
      } else {
        window.removeEventListener("mousemove", onMove);
      }
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  function tick() {
    ticker = window.requestAnimationFrame(tick);

    const current = Date.now();

    time.delta = current - time.current;
    time.elapsed += time.delta;
    time.current = current;

    if (time.delta > 60) {
      time.delta = 60;
    }

    if (particlesArray.length) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      draw();
    }
  }

  function initCanvas() {
    const canvas = document.getElementById("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    if (canvas.getContext) {
      ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.rect(0, 0, canvasRef.current.width, canvasRef.current.height);
      ctx.fillStyle = "#d39a89";
      ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      if (isTouchDevice) {
        window.addEventListener("touchmove", onMove);
      } else {
        window.addEventListener("mousemove", onMove);
      }
      window.addEventListener("resize", resizeCanvas);
    }
  }

  function resizeCanvas() {
    canvasRef.current.width = window.innerWidth;
    canvasRef.current.height = window.innerHeight;
  }

  function onMove(e) {
    const x = isTouchDevice ? e.touches[0].clientX : e.clientX;
    const y = isTouchDevice ? e.touches[0].clientY : e.clientY;
    particlesArray.unshift({
      x,
      y,
      lifeStop: time.elapsed + 300,
    });

    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    draw(e);
  }

  function draw(e) {
    ctx.globalCompositeOperation = "source-over";
    ctx.rect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.fillStyle = "#d39a89";
    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.beginPath();
    particlesArray = particlesArray.filter(
      (particle, index) => index <= 2 || particle.lifeStop >= time.elapsed
    );

    particlesArray.forEach((particle, index) => {
      ctx.globalCompositeOperation = "destination-out";

      ctx.lineCap = "round";

      const e = Math.random() * Math.PI * 2;
      const evol = index / (particlesArray.length - 1);
      ctx.lineWidth = (1 - Math.abs(evol - 0.5) ** 2) * lineWidth;

      if (index <= 2) {
        ctx.quadraticCurveTo(particle.x, particle.y, particle.x, particle.y);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(particle.x, particle.y);
      } else {
        const xc = (particlesArray[index - 1].x + particle.x) / 2;
        const yc = (particlesArray[index - 1].y + particle.y) / 2;

        ctx.quadraticCurveTo(
          particlesArray[index - 1].x + Math.cos(e) * 2,
          particlesArray[index - 1].y + Math.sin(e) * 2,
          xc,
          yc
        );
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(xc, yc);
      }
    });
  }

  return (
    <CustomCanvas
      id="canvas"
      ref={canvasRef}
      style={{ backgroundImage: `url(${Gradient})` }}
    ></CustomCanvas>
  );
}

const CustomCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 0;
`;

export default LandingCanvas;
