import React, { useEffect } from "react";
import styled from "styled-components";
import enums from "./../enums/resume.json";
import Education from "../components/Education";
import Experience from "../components/Experience";
import { gsap } from "gsap";
import { ReactComponent as CVBorder } from "./../assets/svg/cv.svg";
import { ReactComponent as PDF } from "./../assets/svg/pdf.svg";

function Resume() {
  const experiences = enums.experiences;
  const educations = [...enums.educations].reverse();
  const experienceTimeline = gsap.timeline();
  const educationTimeline = gsap.timeline();

  useEffect(() => {
    initExperienceAnimation();
    initEducationAnimation();

    return () => {
      experienceTimeline.kill();
      educationTimeline.kill();
    };
  }, []);

  function initExperienceAnimation() {
    const experienceList = document.querySelector(".experiences");
    experienceTimeline.to(experienceList, {
      y: 0,
      duration: 0.628,
      delay: 0.628,
      ease: "cubic-bezier(0.68, -0.6, 0.32, 1.6)",
    });
  }

  function initEducationAnimation() {
    const educationList = document.querySelector(".educations");
    educationTimeline.to(educationList, {
      y: 0,
      duration: 0.628,
      delay: 0.628,
      ease: "cubic-bezier(0.68, -0.6, 0.32, 1.6)",
    });
  }

  return (
    <View className="view">
      <div className="content">
        <div className="experiences">
          <ul className="list">
            {experiences.map((experience) => (
              <Experience key={experience.company} experience={experience} />
            ))}
          </ul>
          <h1>expériences</h1>
        </div>
        <div className="educations">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/portfolio-anais.appspot.com/o/resume%2FCV.pdf?alt=media&token=982d57ed-f5e8-422a-9a7d-fa7484e493b9"
            target="_blank"
            className="resume-button"
            rel="noreferrer"
          >
            <CVBorder className="border" />
            <PDF className="pdf" />
          </a>
          <h1>formations</h1>
          <ul className="list">
            {educations.map((education) => (
              <Education key={education.name} education={education} />
            ))}
          </ul>
        </div>
      </div>
    </View>
  );
}

const View = styled.div`
  .content {
    grid-template-columns: repeat(12, 1fr);
  }

  h1 {
    font-family: var(--title-font);
    color: var(--pink-color);
    font-size: 52px;
    margin-top: 48px;
  }

  .educations,
  .experiences {
    display: flex;
    flex-direction: column;

    .list {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .experiences {
    position: relative;
    grid-column: 2 / span 5;
    grid-row: 1 / span 4;
    transform: translateY(-100%);

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      top: -24px;
      left: 0;
      height: 50vh;
      background: var(--pink-color);
    }
  }

  .educations {
    position: relative;
    grid-column: 7 / span 5;
    grid-row: 1 / span 4;
    justify-content: flex-end;
    transform: translateY(100%);

    .resume-button {
      position: absolute;
      top: 32px;
      right: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      transition: 0.628s;

      .border {
        fill: var(--lightbrown-color);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        animation: rotation 12s linear infinite;
        tranistion: 0.628s;
      }

      .pdf {
        fill: var(--lightbrown-color);
        position: absolute;
        width: 24px;
        tranistion: 0.628s;
      }

      &:hover {
        transform: scale(1.2);
      }
    }

    h1 {
      text-align: right;
      margin-bottom: 96px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      bottom: -24px;
      right: 0;
      height: 50vh;
      background: var(--pink-color);
    }

    .list {
      flex-direction: column-reverse;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  @media (max-width: 992px) {
    .content {
      grid-template-columns: repeat(2, 1fr);
    }

    h1 {
      font-size: 48px;
    }

    .experiences {
      grid-column: 1 / span 2;
      grid-row: auto;
      flex-direction: column-reverse;
      margin-bottom: 32px;
      padding-top: 24px;

      &::after {
        height: 55vh;
      }

      h1 {
        margin: 0 0 32px 21px;
      }
    }

    .educations {
      grid-column: 1 / span 2;
      grid-row: auto;

      &::after {
        height: 45vh;
      }

      .resume-button {
        top: 10vh;
        right: unset;
        left: 20vw;
      }

      h1 {
        margin-bottom: 76px;
        padding-right: 21px;
      }
    }
  }

  @media (max-width: 760px) {
    h1 {
      font-size: 42px;
    }

    .experiences {
      &::after {
        height: 70vh;
      }
    }

    .educations {
      .resume-button {
        top: 52px;
        left: 0;
        width: 100px;
        height: 100px;

        .pdf {
          width: 18px;
        }
      }
    }
  }
`;

export default Resume;
