import React from "react";
import styled from "styled-components";

function Experience(props) {
  const experience = props.experience;

  return (
    <ExperienceLi className="experience-li">
      <span></span>
      <div>
        <h3>{experience.job}</h3>
        <p>{experience.company}</p>
        <p className="date">{experience.year}</p>
        <ul>
          {experience.missions.map((mission, index) => (
            <li key={index}>{mission}</li>
          ))}
        </ul>
        {experience.technos.length ? (
          <div className="technos">
            {experience.technos.map((techno) => (
              <div className="tag" key={techno}>
                {techno}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </ExperienceLi>
  );
}

const ExperienceLi = styled.li`
  display: flex;
  gap: 16px;
  margin-left: -5px;

  h3,
  p {
    padding: 4px 0;
  }

  h3 {
    font-weight: var(--font-bold);
    color: var(--lightbrown-color);
  }

  p {
    font-size: 12px;

    &.date {
      font-style: italic;
    }
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    flex-shrink: 0;
    background: var(--pink-color);
    margin-top: 6px;
  }

  ul {
    list-style: circle outside;
    margin: 8px 0 0 12px;

    li {
      font-size: 12px;
      line-height: 16px;
      padding: 4px 0;
    }
  }

  .technos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
  }

`;

export default Experience;
